import React, { Component } from "react"
import Container from "../Container"

import "./style.scss"

class Footer extends Component {
  render() {
    return (
      <div className="footer mt-64">
        <Container className="pt-64 pb-32">
          <h3>PT Tredo Media</h3>
          <p>
            <b>Address:</b> Grand Wijaya Center, Block F Wijaya II Street, No
            37, Jakarta
            <br />
            <b>Telephone:</b> +6221 7279 0338 <br />
            <b>Website:</b> https://www.tredo.co.id <br />
            <b>Email:</b> info@tredo.co.id
          </p>
        </Container>
        <div className="footer__copyright">{`© ${new Date().getFullYear()}, Tredo Media`}</div>
      </div>
    )
  }
}

export default Footer
