import React, { PureComponent } from "react"
import { bool, string, any, oneOf } from "prop-types"
import classnames from "classnames"
import { Link } from "gatsby"

import "./style.scss"

class Button extends PureComponent {
  static propTypes = {
    tag: any,
    isBlock: bool,
    size: oneOf(["small", "medium", "large"]),
    variant: oneOf(["primary", "secondary", "outline", "ghost"]),
    className: string,
    isGatsbyLink: bool,
  }

  static defaultProps = {
    tag: "button",
    isBlock: false,
    size: "medium",
    variant: "primary",
    isGatsbyLink: false,
  }

  render() {
    const {
      tag,
      isBlock,
      size,
      variant,
      className,
      isGatsbyLink,
      ...rest
    } = this.props

    const finalClassName = classnames(
      "btn",
      `btn--${size}`,
      `btn--${variant}`,
      {
        "btn--block": isBlock,
      },
      className
    )

    const ButtonTag = tag

    return isGatsbyLink ? (
      <Link className={finalClassName} {...rest} />
    ) : (
      <ButtonTag className={finalClassName} {...rest} />
    )
  }
}

export default Button
