import React, { Component } from "react"
import { node } from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "../Header"
import Footer from "../Footer"
import Container from "../Container"

import "./style.scss"

class Layout extends Component {
  static propTypes = {
    children: node,
  }

  render() {
    const { children } = this.props

    return (
      <StaticQuery
        query={layoutQuery}
        render={data => (
          <div className="layout-wrapper">
            <Container>
              <Header
                siteTitle={data.site.siteMetadata.title}
                siteLogo={data.logoImage.childImageSharp.fluid}
              />
            </Container>
            <div className="border-header"></div>
            <main>{children}</main>
            <Footer />
          </div>
        )}
      />
    )
  }
}

export default Layout

const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    logoImage: file(relativePath: { eq: "images/tredo-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
