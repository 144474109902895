import React, { Component } from "react"
import { any, string } from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"

import "./style.scss"

class Header extends Component {
  static propTypes = {
    siteTitle: string,
    siteLogo: any,
  }

  render() {
    const { siteTitle, siteLogo } = this.props

    return (
      <div className="header">
        <Link to="/">
          <Img fluid={siteLogo} alt={`${siteTitle}'s Logo`} />
        </Link>
      </div>
    )
  }
}

export default Header
