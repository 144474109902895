import React, { Component } from "react"
import { string, node } from "prop-types"
import classnames from "classnames"

import "./style.scss"

class Container extends Component {
  static propTypes = {
    children: node,
    className: string,
  }

  render() {
    const { children, className, ...rest } = this.props

    const finalClassName = classnames("container-wrapper", className)

    return (
      <div className={finalClassName} {...rest}>
        {children}
      </div>
    )
  }
}

export default Container
